import React from "react";

export const About = () => {
  return (
    <div
      name="about"
      className="min-h-screen w-full bg-gradient-to-b from-gray-800 to-black text-white"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">About</p>
        </div>
        <p className="text-xl mt-10 text-justify  " style={{ lineHeight: '1.5' }}>
          Hello! I’m a web developer with a passion for creating websites and applications that are easy to use and help people get things done.
          Over the years, I’ve worked on many different projects, such as online stores, booking systems, and websites for businesses. I use a variety of technologies to make sure the solutions I build are reliable, easy to navigate, and work well for everyone, whether they're on a phone or a computer.


        </p>
        <br />
        <p className="text-xl text-justify" style={{ lineHeight: '1.5' }}>
          I believe in building things that solve real problems. When I start a project, my main goal is to make sure it runs smoothly and meets the needs of the people who will use it. Whether it’s improving the performance of a website or creating a system that makes everyday tasks easier for a business, I enjoy taking ideas and turning them into something people can actually use. You can check out my portfolio to see some of the work I’ve done and find out how I can help you with your next project!
        </p>
      </div>
    </div>
  );
};
